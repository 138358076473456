import React from 'react'
import { StaticQuery, graphql, Link, navigate } from 'gatsby'

import styled from '@emotion/styled'
import idx from 'idx'
import formatDate from '../../utils/formatDate'
import Text from '../common/Text'
import chevron from '../../../static/icons/arrow-icons/chevron-icon-violet.svg'
import getImage from '../../utils/getImage'
import { theme } from '../../styles'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  border-radius: 6px;
  background: ${theme.color.white};
  overflow: hidden;
  border-width: 0px;
  cursor: pointer;

  transition: all 125ms ease-in-out;

  &:hover {
    box-shadow: 0px 2px 20px 0px ${theme.color.darkShadow};
    transform: scale(1.01);
  }

  @media (max-width: 1050px) {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    &:hover {
      box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
      transform: none;
    }
  }
`

const ImgWrapper = styled.div`
  width: 50%;

  @media (max-width: 1050px) {
    width: 100%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  padding: 60px;

  @media (max-width: 1050px) {
    width: 100%;
    padding: 32px;
  }

  @media (max-width: 800px) {
    padding: 24px 16px;
  }
`

const BottomContent = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ReadMoreLink = styled(Link)`
  display: flex;
`

const Arrow = styled.img`
  width: 12px;
  margin-left: 8px;
  margin-top: 6px;
  transform: rotate(270deg);
`

const FeaturedPost = () => {
  const featuredPostQuery = graphql`
    query postQuery {
      allPrismicPost(
        limit: 5
        filter: { data: { post_type: { eq: "Blog" }, publish_date: { ne: null } }, lang: { eq: "en-us" } }
        sort: { fields: data___publish_date, order: DESC }
      ) {
        edges {
          node {
            id
            uid
            data {
              title {
                text
              }
              hide_from_feeds
              password
              banner {
                alt
                url
                gatsbyImageData(width: 655)
              }
              description
              publish_date
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={featuredPostQuery}
      render={data => {
        const publicPosts = data.allPrismicPost.edges.filter(post => {
          // Only allow non hidden and non PW protected posts to be featured
          if (!post.node.data.hide_from_feeds && !post.node.data.password) {
            return post
          }
          return null
        })
        const featPost = publicPosts[0].node

        const defaultImageUrl =
          'https://images.prismic.io/audioeye-web/953cc937-895c-47a9-ab87-0e60c31f5c1e_audioeye-default-blog-image.png?auto=compress,format&w=655'
        const image = featPost.data.banner || { url: defaultImageUrl }

        return (
          <Wrapper
            className="link-list-item focus-outline"
            onClick={() => navigate(`/post/${idx(featPost, _ => _.uid)}`)}
            onKeyDown={e => {
              if (e.key === 'Enter') navigate(`/post/${idx(featPost, _ => _.uid)}`)
            }}
            tabIndex="0"
          >
            <ImgWrapper>{getImage(image, Img)}</ImgWrapper>
            <CardContent>
              <Text heading3 aria-level="2" numberOfLines={3}>
                {featPost.data.title.text}
              </Text>
              <Text smallBody numberOfLines={3} style={{ marginTop: '16px' }}>
                {featPost.data.description}
              </Text>
              <BottomContent>
                <Text smallBody color="gray700">
                  {featPost.data.publish_date && formatDate(featPost.data.publish_date)}
                </Text>
                <ReadMoreLink
                  to={`/post/${idx(featPost, _ => _.uid)}`}
                  onClick={e => e.stopPropagation()}
                  className="no-focus"
                  tabIndex="-1"
                >
                  <Text smallBodyHeavy text="Read Full Article" color="purple500" underline>
                    Read Full Article
                  </Text>
                  <Arrow alt="" role="presentation" src={chevron} />
                </ReadMoreLink>
              </BottomContent>
            </CardContent>
          </Wrapper>
        )
      }}
    />
  )
}

export default FeaturedPost
