/* eslint-disable camelcase */
import React, { Fragment, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import idx from 'idx'

import Text from '../../common/Text'
import Space from '../../common/Space'
import PaginatedPageBlock from '../../common/PaginatedPageBlock'
import PostGridItem from './PostGridItem'
import SearchInput from '../../form/SearchInput'

const Wrapper = styled.div`
  position: relative;
`

const GridWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1400px) {
    justify-content: space-evenly;
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -50%;
  height: 100%;
  z-index: -1;
  background-color: #fafafa;
`

const BottomWrapper = styled.nav`
  display: flex;
  justify-content: center;
  @media (max-width: 1050px) {
    flex-wrap: wrap;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 49px;
`

const ToolBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const TitleText = styled(Text)`
  @media (max-width: 1050px) {
    margin-top: 16px;
  }
`

const Ellipses = styled.div`
  display: flex;
  margin-left: 10px;
  padding: 0px 8px;
  justify-content: center;
  align-items: flex-end;
`

const PaginatedPostsGrid = ({
  data,
  hasToolbar,
  truncatedData,
  truncated,
  handleSearch,
  search,
  setPage,
  page,
  totalPages,
  setAllowSearch,
}) => {
  const [typingTimer, setTypingTimer] = useState()
  const [activePage, setActivePage] = useState(1)
  const [truncation, setTruncation] = useState(data && data.total_pages > 7)

  const onPreviousClick = () => {
    if (activePage !== 1) {
      setPage(page - 1)
      setActivePage(page - 1)
    }
  }
  const onNextClick = () => {
    if (activePage !== totalPages) {
      setPage(page + 1)
      setActivePage(page + 1)
    }
  }

  const handleKeyUp = () => {
    clearTimeout(typingTimer)
    setTypingTimer(setTimeout(doneTyping, 750))
  }

  const handleKeyDown = () => {
    clearTimeout(typingTimer)
  }

  function doneTyping() {
    setAllowSearch(true)
  }

  const handleSearchAccessible = value => {
    document.getElementById('at_message').innerHTML = `Search filtering value: ${value}`
    return handleSearch(value)
  }

  useEffect(() => {
    if (page === 1 && !search) {
      // eslint-disable-next-line no-unused-vars
      const shiftedData = data.results.shift()
    }
    if (search) {
      setTruncation(false)
    }
    if (!search || data.total_pages > 7) {
      setTruncation(true)
    }
  }, [data])

  return (
    <Wrapper>
      <BG className="bg" />
      <Space height={80} />
      <Header>
        {search ? (
          <Text heading2 color="gray700">
            Search Results for: {search}
          </Text>
        ) : (
          data && data.results[0] && <TitleText heading2>Recent Posts</TitleText>
        )}
        {hasToolbar && (
          <ToolBarWrapper>
            <SearchInput
              placeholder="Search Blog..."
              value={search}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onChange={e => handleSearchAccessible(e.target.value)}
              style={{ backgroundColor: 'transparent' }}
              aria-label="Search Blog. You are currently on a search field, start typing to filter results"
            />
          </ToolBarWrapper>
        )}
      </Header>
      <GridWrapper>
        {data ? (
          data.results.map((item, i) => (
            <Fragment key={item.data.title[0].text}>
              <PostGridItem
                uid={item.uid}
                publishDate={item.data.publish_date}
                title={item.data.title[0].text}
                index={i}
                img={idx(item.data, _ => _.banner)}
                author={idx(item.data, _ => _.author)}
                guestAuthor={idx(item.data, _ => _.guest_author_name)}
              />
            </Fragment>
          ))
        ) : (
          <Text bodyHeavy>Nothing found</Text>
        )}
      </GridWrapper>
      <BottomWrapper role="navigation" aria-label="Pagination" id="pagination-nav">
        <PaginatedPageBlock
          page="previous"
          ariaLabel="previous"
          disabled={activePage === 1}
          onClick={onPreviousClick}
          activePage={activePage}
        />
        {!truncation ? (
          new Array(totalPages).fill(' ').map((block, i) => (
            <PaginatedPageBlock
              key={i}
              page={i + 1}
              activePage={activePage}
              disabled={false}
              onClick={e => {
                setPage(i + 1)
                setActivePage(i + 1)
                e.target.blur()
              }}
            />
          ))
        ) : (
          <>
            {data &&
              data.total_pages &&
              truncatedData.map((block, i) =>
                page <= data.total_pages - 2 ? (
                  <PaginatedPageBlock
                    key={i}
                    page={truncated ? page - 5 + i : i + 1}
                    activePage={activePage}
                    disabled={false}
                    onClick={e => {
                      if (truncated) {
                        setPage(page - 5 + i)
                        setActivePage(page - 5 + i)
                      } else {
                        setPage(i + 1)
                        setActivePage(i + 1)
                      }
                      e.target.blur()
                    }}
                  />
                ) : (
                  <>
                    <PaginatedPageBlock
                      key={i}
                      page={data.total_pages - 7 + i}
                      setPage={setPage}
                      activePage={activePage}
                      disabled={false}
                      onClick={e => {
                        if (e.target.textContent) {
                          setPage(Number(e.target.textContent))
                          setActivePage(Number(e.target.textContent))
                        }
                        e.target.blur()
                      }}
                    />
                  </>
                )
              )}
            {data && data.total_pages && page <= data.total_pages - 4 && (
              <Ellipses aria-label="Ellipses. Additional pages hidden. Click on next button to proceed">
                <Text smallBodyHeavy aria-hidden>
                  ...
                </Text>
              </Ellipses>
            )}
            {data && data.total_pages && page <= data.total_pages - 3 && (
              <PaginatedPageBlock
                page="last"
                ariaLabel={`${data && data.total_pages && data.total_pages}. Last page`}
                totalPages={data.total_pages}
                activePage={activePage}
                onClick={e => {
                  setPage(data.total_pages)
                  setActivePage(data.total_pages)
                }}
              />
            )}
          </>
        )}
        <PaginatedPageBlock
          disabled={data && data.total_pages && page === data.total_pages}
          page="next"
          ariaLabel="next"
          onClick={onNextClick}
          activePage={activePage}
        />
      </BottomWrapper>
      <Space height={80} />
    </Wrapper>
  )
}

export default PaginatedPostsGrid
