/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import idx from 'idx'
import Fade from 'react-reveal/Fade'
import { Link, navigate } from 'gatsby'
import formatDate from '../../../utils/formatDate'
import { theme } from '../../../styles'
import Text from '../../common/Text'
import Space from '../../common/Space'
import generateSrcSet from '../../../utils/generateSrcSet'

const Card = styled.li`
  box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
  border-radius: 6px;
  background: #fff;
  overflow: hidden;
  position: relative;
  max-width: 400px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  border-width: 0px;
  transition: all 125ms ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 0px 2px 20px 0px ${theme.color.darkShadow};
    transform: scale(1.025);
  }

  @media (max-width: 1050px) {
    margin-bottom: 32px;
  }

  @media (max-width: 800px) {
    &:hover {
      box-shadow: 0px 0px 14px 3px ${theme.color.dropShadow};
      transform: none;
    }
  }
`

const Img = styled.img`
  width: 100%;
  align-self: center;
`

const CardContent = styled.div`
  padding: 16px;

  a {
    display: block;
    min-height: 58px;
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const PostGridItem = ({ publishDate, title, uid, img, author, guestAuthor, index }) => {
  let postAuthor =
    author && idx(author, _ => _.data.first_name) && idx(author, _ => _.data.last_name)
      ? `${idx(author, _ => _.data.first_name[0].text)} ${idx(author, _ => _.data.last_name[0].text)}`
      : idx(guestAuthor, _ => _[0].text)
  if (!postAuthor) {
    postAuthor = 'AudioEye'
  }
  let modifiedImg = img
  if (!img.url) {
    modifiedImg = {
      url: 'https://images.prismic.io/audioeye-web/953cc937-895c-47a9-ab87-0e60c31f5c1e_audioeye-default-blog-image.png?auto=compress,format&w=400',
      alt: 'AudioEye Logo',
      dimensions: {},
    }
  }

  const src = idx(modifiedImg, _ => _.url)
  const srcSet = generateSrcSet(
    src,
    idx(modifiedImg, _ => _.dimensions),
    400
  )

  return (
    <Fade bottom delay={index * 80}>
      <Card
        className="link-list-item focus-outline"
        onClick={() => navigate(`/post/${uid}`)}
        onKeyDown={e => {
          if (e.key === 'Enter') navigate(`/post/${uid}`)
        }}
        tabIndex="0"
      >
        <Img alt={idx(modifiedImg, _ => _.alt) || ''} src={src} loading="lazy" srcSet={srcSet} />
        <CardContent>
          <CardHeader>
            <Text smallBody color="gray400">
              {publishDate && formatDate(publishDate)}
            </Text>
            <Text smallBody color="gray400">
              {postAuthor}
            </Text>
          </CardHeader>
          <Space height={12} />
          <Link to={`/post/${uid}`} onClick={e => e.stopPropagation()} className="no-focus" tabIndex="-1">
            <Text bodyHeavy numberOfLines={2}>
              {title}
            </Text>
          </Link>
          <Space height={16} />
        </CardContent>
      </Card>
    </Fade>
  )
}

export default PostGridItem
