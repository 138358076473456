import React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../styles'
import magn from '../../../static/icons/misc-icons/search-icon.svg'

const Input = styled.input`
  border-width: 0px;
  background-color: ${theme.color.white};
  position: relative;
  border-radius: 12px;

  font-size: ${theme.fonts.size.smallBody};
  font-family: ${theme.fonts.family.bodyHeavy};
  height: 40px;
  width: 100%;

  padding-left: 10px;
  ::placeholder {
    color: ${theme.color.gray400};
  }

  &:focus {
    outline-offset: 8px;
    outline: 3px solid #7119e1 !important;
  }

  @media (max-width: 1050px) {
    display: none;
  }
`

const Wrapper = styled.div`
  border-bottom: solid 2px ${theme.color.offBlack};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 280px;
  @media (max-width: 1050px) {
    display: none;
  }
`

const InComp = props => (
  <Wrapper role="search">
    <Input {...props} />
    <img src={magn} alt="" style={{ width: '18px', marginLeft: '-20px' }} />
  </Wrapper>
)

export default InComp
