import React from 'react'
import styled from '@emotion/styled'
import Text from './Text'
import chevron from '../../../static/icons/arrow-icons/dropdown-chevron-icon.svg'

const PageBlockWrapper = styled.button`
  min-width: 40px;
  min-height: 35px;
  border: 1px solid ${p => p.theme.color.offBlack};
  border-radius: 3px;
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${p => (p.isActive ? p.theme.color.offBlack : p.theme.color.white)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 0px 10px;
  z-index: 2;

  &:first-of-type {
    margin-left: 0px;
  }

  @media (max-width: 1050px) {
    min-width: unset;
    margin-left: 8px;
    margin-top: 8px;
  }
`

const Img = styled.img`
  width: 14px;
  transform: ${p => (p.previous ? 'rotate(90deg)' : 'rotate(270deg)')};
`

const modifyPage = page => {
  switch (page) {
    case 'previous':
      return <Img src={chevron} previous alt="" />
    case 'next':
      return <Img src={chevron} next alt="" />
    default:
      return page
  }
}

const PaginatedPageBlock = ({ totalPages, page, disabled, activePage, ariaLabel, ...props }) => {
  const modifiedPage = modifyPage(page)

  return (
    <PageBlockWrapper
      aria-label={ariaLabel || ''}
      disabled={disabled}
      isActive={page === activePage}
      aria-current={page === 'previous' || page === 'next' ? '' : page === activePage}
      aria-describedby="pagination-nav"
      key={page}
      {...props}
    >
      {page === 'last' && totalPages ? (
        <Text
          smallBodyHeavy
          color={page === activePage ? 'white' : ''}
          onClick={e => {
            if (e.target.parentNode) e.target.parentNode.blur()
          }}
        >
          {totalPages}
        </Text>
      ) : page === 'previous' || page === 'next' || page === 'last' ? (
        modifiedPage
      ) : (
        <Text
          smallBodyHeavy
          color={page === activePage ? 'white' : ''}
          onClick={e => {
            if (e.target.parentNode) e.target.parentNode.blur()
          }}
        >
          {page}
        </Text>
      )}
    </PageBlockWrapper>
  )
}
export default PaginatedPageBlock
