/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import SEO from '../components/SEO'
import Text from '../components/common/Text'
import Space from '../components/common/Space'
import Layout from '../components/Layout'
import { getPrismicPosts } from '../utils/prismicPostAPI'

import PaginatedPostsGrid from '../components/pageSlices/blog/PaginatedPostsGrid'
import FeaturedPost from '../components/postSlices/FeaturedPost'

const Wrapper = styled.div`
  position: relative;
  width: calc(100% + 150px);
  margin-left: -75px;
  margin-top: 48px;
  @media (max-width: 1050px) {
    margin-left: 0px;
    width: 100%;
  }
`

const Blog = () => {
  const [page, setPage] = useState(1)
  const [allowSearch, setAllowSearch] = useState(true)
  const [search, setSearch] = useState('')
  const [data, setData] = useState()
  const [truncatedData, setTruncatedData] = useState()
  const [truncated, setTruncated] = useState(false)

  const handleSearch = text => setSearch(text)

  const onPageSelected = pageNum => {
    setPage(pageNum)
    setAllowSearch(pageNum)
  }

  useEffect(() => {
    if (allowSearch) {
      try {
        getPrismicPosts({
          page: allowSearch === true ? 1 : page,
          // Remove first post to avoid duplicate display of featured post
          pageSize: page === 1 ? 16 : 15,
          full_text: search,
        }).then(res => {
          const pagesArr = new Array(res.total_pages).fill(' ')
          pagesArr.length = 8
          // eslint-disable-next-line no-unused-expressions
          page >= 6 ? setTruncated(true) : setTruncated(false)
          setTruncatedData(pagesArr)
          setData(res)
          if (allowSearch === true) setPage(1)
          setAllowSearch(false)
        })
      } catch (error) {
        console.log(error)
      }
    }
  }, [page, search, allowSearch])

  return (
    <Layout customSEO showHeader showCTA>
      <SEO
        title="Blog | AudioEye"
        pathname={typeof window !== 'undefined' ? window.location.pathname : null}
        desc="Check out the latest stories about digital accessibility, WCAG / ADA website compliance, AudioEye, and more."
      />
      <Wrapper>
        <Text heading1>AudioEye Blog</Text>
        <Space height={49} />
        <FeaturedPost />
        <Space height={80} />
        {data && (
          <PaginatedPostsGrid
            data={data}
            truncatedData={truncatedData}
            truncated={truncated}
            handleSearch={handleSearch}
            search={search}
            page={page}
            setPage={onPageSelected}
            totalPages={data.total_pages}
            hasToolbar
            setAllowSearch={setAllowSearch}
          />
        )}
      </Wrapper>
    </Layout>
  )
}

export default Blog
